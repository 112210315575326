import React from 'react';
import Layout from '../components/layout';
import Box, { BoxTitle } from '../elements/box';
import Card, { CardContent, CardImage, CardTitle, CardParagraph, CardButton } from '../elements/card';

const Contes = () => (
  <Layout>
    <Box background="primary-light">
      <BoxTitle title="Contes de Jorge BUCAY" />
    </Box>
    <div className="columns">
      <div className="column">
        <Card>
          <CardContent>
            <CardTitle title="L'étoile de mer" />
          </CardContent>
          <CardContent>
            <em className="content justified-content">
              <p>Un matin, un petit garçon se promenait sur la plage déserte avec son grand-père. 
                Ils entretenaient tous deux une conversation très enrichissante.</p>
              <p>Le petit garçon était particulièrement curieux de nature et posait plein de questions à son grand-père, 
                    doté d’une très grande sagesse.</p>
            </em>
            <CardParagraph>
              <em>
                  <br />
                  <p>À toute les deux minutes, le grand-père se penchait, ramassait quelque chose par terre qu’il rejetait 
                    aussitôt dans l’océan. Intrigué, après la dixième fois, le petit garçon s’est arrêté de marcher et a demandé 
                    à son grand-père :</p>
                  <p>« Que fais-tu, grand-papa ? »</p>
                  <p>– Je rejette les étoiles de mer dans l’océan.</p>
                  <p>– Pourquoi fais-tu cela, grand-papa ?</p>
                  <p>– Vois-tu, mon petit fils, c’est la marée basse, et toutes ces étoiles de mer ont échoué sur la plage.</p>
                  <p>Si je ne les rejette pas à la mer, elles vont mourir parce que dans quelques heures elles sécheront sous les 
                    rayons chauds du soleil.</p>
                  <p>– Je comprends, a répliqué le petit garçon, « mais grand-papa, il doit y avoir des milliers d’étoiles de mer 
                    sur cette plage, tu ne peux pas toutes les sauver. Il y en a tout simplement trop.</p>
                  <p>Et de plus, grand-papa, le même phénomène se produit probablement à l’instant même partout sur des milliers de 
                    plages à travers le monde. Ne vois-tu pas, grand-papa, que tu ne peux rien y changer ? »</p>
                  <p>Le grand-père a souri et s’est penché, il a ramassé une autre étoile de mer.</p>
                  <p>En la jetant à la mer, il a répondu ceci à son petit fils :</p>
                  <p>« Tu as peut-être raison, mon garçon, mais ça change tout pour celle-là ! »</p>
                </em>
              </CardParagraph>
            </CardContent>
            <footer class="card-footer">
              <CardButton />
            </footer>
        </Card>
      </div>
      <div className="column">
        <Card>
          <CardContent>
            <CardTitle title="La valeur de la bague" />
          </CardContent>
          <CardContent>
            <em className="content justified-content">
              <p>- Je viens vous voir, maître, parce que j'ai l'impression d'avoir si peu d'importance que cela m'ôte toute 
                  envie de faire quoi que ce soit. Tout le monde dit que je suis un bon à rien, que je suis maladroit et stupide. 
                  Comment puis-je m'améliorer ? Comment m'y prendre pour être mieux considéré ?</p>
            </em>
            <CardParagraph>
              <em>
                <br />
                <p>Le maître, sans le regarder, lui dit :</p>
                <p>- Je suis vraiment désolé, mon garçon. Je ne peux t'aider, car je dois d'abord résoudre un problème personnel. 
                  Peut-être, plus tard....</p>
                <p>Et, faisant une pause, il ajouta :</p>
                <p>- Si tu voulais m'aider, toi, je résoudrais ce problème plus vite, et, ensuite, peut-être pourrais-je te venir en 
                  aide.</p>
                <p>- Euh....j'en serai ravi, maître, bredouilla le jeune homme en ayant de nouveau le sentiment qu'on ne lui accordait 
                  que peu d'importance et qu'on remettait ses besoins à plus tard.</p>
                <p>- Bien, poursuivit le maître.</p>
                <p>Il retira une bague qu'il portait au petit doigt de la main gauche et, la donnant au jeune homme, il ajouta :</p>
                <p>- Prends le cheval qui est dehors et va jusqu'au marché. Je dois vendre cette bague pour rembourser une dette. Il te 
                  faut en obtenir la plus grosse somme possible et, de toute façon, pas moins d'une pièce d'or. Va t-en et reviens avec 
                  cette pièce aussi vite que tu pourras.</p>
                <p>Le garçon prit la bague et s'en fut. Aussitôt arrivé sur le marché, il se mit en devoir de la proposer aux marchands; 
                  ceux-ci la regardaient avec intérêt, jusqu'à ce qu'il annonce le prix qu'il en demandait.</p>
                <p>Dès qu'il mentionnait la pièce d'or, certains ricanaient, d'autres détournaient la tête; seul un vieillard fut assez 
                  aimable pour prendre la peine de lui expliquer qu'une pièce d'or était bien trop précieuse pour l'échanger contre une 
                  bague. Désirant lui venir en aide, quelqu'un lui offrit une pièce d'argent et un récipient en cuivre, mais le garçon 
                  avait des ordres : ne pouvant accepter moins d'une pièce d'or, il rejeta l'offre.</p>
                <p>Après avoir proposé le bijoux à toutes les personnes qu'il croisa sur le marché - au moins une centaine -, abattu par 
                  son échec, il enfourcha son cheval et rentra. Comme il aurait aimé avoir une pièce d'or à donner au maître pour le 
                  soulager de ses soucis et recevoir son conseil ainsi que son aide ! Il revint chez celui-ci.</p>
                <p>- Maître, dit-il, je regrette. Il est impossible d'obtenir ce que tu demandes. Peut-être aurais-je pu échanger la 
                  bague contre deux ou trois pièces d'argent, mais je ne crois pouvoir tromper personne sur sa valeur véritable.</p>
                <p>- Tu viens de dire une chose très importante, mon jeune ami, répondit le maître en souriant. Il nous faut d'abord 
                  connaître la véritable valeur de cette bague. Reprends ton cheval et rends-toi chez le bijoutier. Qui mieux que lui peut 
                  l'estimer ? Dis-lui que tu voudrais la vendre et demande-lui combien il t'en donnerait. Mais quoi qu'il te propose ne la 
                  lui vends pas. Reviens ici avec ma bague.</p>
                <p>Le jeune homme reprit sa chevauchée.</p>
                <p>Le bijoutier examina la bague à la lumière d'une lampe à huile, il la regarda avec sa loupe, la soupesa et finit 
                  par dire :</p>
                <p>- Mon garçon, dis au maître que, s'il veut vendre sa bague tout de suite, je ne peux lui en donner plus de 
                  cinquante-huit pièces d'or.</p>
                <p>- Cinquante-huit pièces !  s'exclama le jeune homme.</p>
                <p>- Oui, répliqua le bijoutier. Je sais qu'avec du temps on pourrait en obtenir plus de soixante-dix, mais si la vente 
                  est pressée...</p>
                <p>Tout ému, le garçon courut chez le maître pour lui raconter l'histoire.</p>
                <p>- Assieds-toi, dit celui-ci après l'avoir écouté. Tu es comme cette bague : un bijoux précieux, unique. En tant que 
                  tel, seul peut t'estimer un véritable expert. Pourquoi exiger du premier venu qu'il découvre ta vraie valeur ?</p>
                <p>Et, disant cela, il remit la bague au petit doigt de sa main gauche.</p>
              </em>
            </CardParagraph>
          </CardContent>
          <footer class="card-footer">
            <CardButton />
          </footer>
        </Card>
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <Card>
          <CardContent>
            <CardTitle title="Les deux moines et la jeune fille" />
          </CardContent>
          <CardContent>
            <em className="content justified-content">
              <p>Deux moines zen s’apprêtaient à traverser une rivière à gué, lorsqu’arriva une belle jeune fille.</p>
              <br />
              <p>Elle aussi souhaitait traverser, mais elle était effrayée par la violence du courant.</p>
            </em>
            <CardParagraph>
              <em>
                <br />
                <p>Alors l’un des moines la prit en souriant sur ses épaules et la porta de l’autre côté de la rivière.</p>
                <p> Son compagnon fulminait : un moine ne doit pas toucher le corps d’une femme !</p>
                <p>Et tout le long du trajet, il ne desserra plus les dents.</p>
                <p>Deux heures plus tard, lorsqu’ils arrivèrent en vue du monastère, il lui annonça sur un ton de reproche 
                  qu’il allait informer le maître de ce qui s’était passé :</p>
                <p>– Ce que tu as fait est honteux et interdit par notre règle !</p>
                <p>Son compagnon s'étonna :</p>
                <p>– Qu’est-ce qui est honteux ? Qu’est-ce qui est interdit ?</p>
                <p>– Comment ? Tu as oublié ce que tu as fait ? Tu ne t’en souviens donc pas ? Tu as porté une belle jeune 
                  fille sur tes épaules !</p>
                <p>– Ah oui, se souvint le premier en riant. Tu as raison. Mais il y a deux bonnes heures que je l’ai laissée 
                  sur l’autre rive, tandis que toi, tu la portes toujours sur ton dos !</p>
              </em>
            </CardParagraph>
          </CardContent>
          <footer class="card-footer">
            <CardButton />
          </footer>
        </Card>
      </div>
      <div className="column">
        <Card>
          <CardContent>
            <CardTitle title="Le Chercheur" />
          </CardContent>
          <CardContent>
            <em className="content justified-content">
              <p>C'est l'histoire d'une homme que je définirais comme un chercheur...</p>
              <p>Un chercheur est quelqu'un qui cherche, pas forcément quelqu'un qui trouve. Ce n'est pas non plus quelqu'un 
                qui, nécessairement, sait ce qu'il cherche.</p>
              <p>C'est simplement quelqu'un dont la vie est une quête.</p>
            </em>
            <CardParagraph>
              <em>
                <br />
                <p>Un jour, ce chercheur eut le sentiment qu'il devait se rendre à la ville de Kammir.</p>
                <p>Il avait appris à tenir compte de ces sensations qui venaient d'un endroit inconnu de lui-même. Aussi, il 
                  quitta tout et partit.</p>
                <p>Au bout de deux jours de marche sur les chemins poudreux, il aperçut au loin Kammir. Un peu avant d'arriver 
                  à la ville, une colline à droite du sentier attira son attention. Merveilleusement verte, elle était couverte 
                  d'arbres, de fleurs, d'oiseaux enchanteurs, et entièrement entourée d'une sorte de petite palissade en bois 
                  verni.</p>
                <p>Un portillon en bronze l'invitait à entrer. Il eut tout à coup l'impression d'oublier la ville et succomba 
                  à la tentation de se reposer en ce lieu. Le chercheur franchit le portillon et avança lentement entre les 
                  pierres blanches, qui semblaient éparpillées un peu au hasard, entre les arbres.</p>
                <p>Il laissa ses yeux se poser comme des papillons sur chaque détail de ce paradis multicolore. Ses yeux étaient 
                  ceux d'un chercheur et, sans doute pour cette raison, il découvrit cette inscription sur l'une des pierres : 
                  Abdul Tareg, vécut 8 ans, 6 mois 2 semaines et 3 jours.</p>
                <p>Il eut un léger sursaut en prenant conscience que cette pierre n'était pas une pierre ordinaire : il s'agissait 
                  'une pierre tombale.</p>
                <p>Il éprouva une peine immense à la pensée qu'un si jeune enfant était enterré la. Regardant autour de lui, 
                  l'homme se rendit compte que la pierre d'à côté portait également une inscription.</p>
                <p>Il s'approcha pour la lire : Yamir Kalib, vécut 5 ans, 8 mois et 3 semaines. Le chercheur se sentit envahi 
                  d'une terrible émotion. Cet endroit merveilleux était un cimetière, et chacune des pierres, une tombe.</p>
                <p>Une à une, il entreprit de lire les pierres tombales. Toutes portaient des inscriptions semblables : un nom 
                  et la durée de vie exacte du défunt. Mais ce qui le plongea dans l'épouvante, ce fut de constater que celui 
                  qui avait vécu le plus longtemps avaità peine plus de onze ans...</p>
                <p>Accablé par un effroyable chagrin, il s'assit et se mit à pleurer.</p>
                <p>Passant par là, le gardien du cimetière s'approcha. Il le regarda un moment en silence, puis lui demanda s'il 
                  pleurait un membre de sa famille.</p>
                <p>" Non, aucun parent, dit le chercheur. Que se passe -t-il avec cette population ? Quelque chose de terrible 
                  y a t-il dans cette ville ? Pourquoi tant d'enfants défunts enterrés en ce lieu ? Quelle est l'horrible 
                  malédiction qui pèse ces gens et les a obligés à construire un cimetière d'enfants ? !!! "</p>
                <p>Le vieil homme sourit et dit : " Calmez-vous. Il n'y a aucune malédiction. Ce qui se passe, c'est que nous 
                  avons ici une vieille coutume. Je vais vous raconter... "</p>
                <p>Lorsqu'un adolescent entre en sa quinzième année, ses parents lui font présent d'un carnet comme celui que 
                  j'ai ici, pendu à mon cou. Il est de tradition chez nous, à partir de ce moment, que chaque fois qu'on jouit 
                  intensément de quelque chose, on ouvre le carnet et on note dedans :</p>
                <p>- à gauche, ce qui a donnée de la joie...</p>
                <p>- à droite, combien de temps a duré cette joie.</p>
                <p>" Il a rencontré sa fiancé, il en est tombé amoureux. Combien de temps a duré cette immense passion et le 
                  plaisir de la connaître ? Une semaine, deux, trois semaines et demie ? ... Et ensuite ....l'émotion du premier 
                  baiser, le merveilleux plaisir du premier baiser, et combien de temps a t-il duré ? La minute et demie du baiser, 
                  deux jours, une semaine ? ... Et la grossesse de sa femme, la naissance de son premier enfant ? ... Et le mariage 
                  de ses amis ? ... Et le voyage le plus désiré ? ... Et les retrouvailles avec le frère rentré d'un pays lointain ? 
                  Des heures, des jours ? ... Ainsi notons-nous peu à peu, dans ce carnet, chaque moment dont nous jouissons... 
                  chaque moment "</p>
                <p>"Lorsque quelqu'un meurt, nous avons coutume d'ouvrir son carnet et de faire la somme des moments de joie pour 
                  l'inscrire sur sa tombe.</p>
                <p>PARCE QUE, POUR NOUS, CE TEMPS EST LE SEUL ET VERITABLE VECU.</p>
              </em>
            </CardParagraph>
          </CardContent>
          <footer class="card-footer">
            <CardButton />
          </footer>
        </Card>
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <Card>
          <CardContent>
            <CardTitle title="L'homme qui avait peur de se perdre" />
          </CardContent>
          <CardContent>
            <em className="content justified-content">
              <p>Il était une fois un homme qui souffrait d’une peur absurde. Il craignait de se perdre au milieu des autres.</p>
              <br />
              <p>Tout commence une nuit, au cours d’une soirée déguisée, quand il était jeune.</p>
            </em>
            <CardParagraph>
              <em>
                <br />
                <p>Quelqu’un avait sorti une photographie sur laquelle tous les invités apparaissaient en rang d’oignons. 
                  Mais en la regardant, il n’avait pas pu se reconnaître.</p>
                <p>L’homme avait choisi un costume de pirate, avec un bandeau sur l’œil et un foulard sur la tête. Mais beaucoup 
                  avaient choisi un déguisement similaire. Son maquillage était constitué d’une touche de rouge écarlate au niveau 
                  des joues et d’un peu de suie en guise de moustache. Mais des déguisements constitués de pommettes rouges et de 
                  moustaches noires, il y en avait un certain nombre.</p>
                <p>Il s’était beaucoup amusé au cours de cette soirée, mais sur la photographie, tous semblaient s’être beaucoup 
                  amusés aussi. Finalement, il se souvint qu’au moment où la photographie avait été prise, il était prêt d’une 
                  belle blonde, et il essaya de la retrouver.</p>
                <p>Ce fut peine perdue. La moitié des femmes de la soirée étaient blondes et la plupart d’entre elles étaient au 
                  bras d’un pirate.</p>
                <p>Cette expérience eut un impact important sur cet homme, et à cause de cela, pendant des années, il n’assista à 
                  aucune soirée de peur de se perdre à nouveau.</p>
                <p>Mais un jour, il trouva une solution. A partir de maintenant et en toute circonstance, il s’habillerait toujours 
                  de marron : chemise marron, pantalon marron, sacoche marron, chaussettes et chaussures marron.</p>
                <p>« Si quelqu’un prend une photographie, je pourrais toujours me reconnaître car celui vêtu de marron, se sera moi. »
                   Pensa-t-il.</p>
                <p>Le temps passa, et notre homme eut l’occasion de mettre son astuce à l’épreuve plus d’une fois. En se voyant dans
                   les miroirs des grands magasins, au beau milieu d’une foule, il se répétait calmement :  « Je suis l’homme en 
                   marron. »</p>
                <p>Et puis, un certain hiver, un groupe d’amis lui offrit un forfait d’une après midi aux bains turcs. L’homme accepta 
                  enchanté. Il n’avait jamais été dans un endroit comme cela et il avait entendu de la bouche de ses amis tous les 
                  avantages et bénéfices de ce type de bains.</p>
                <p>Il se rendit aux bains turcs et on lui donna deux serviettes. Puis on l’invita à se rendre aux vestiaires pour se 
                  déshabiller. L’homme posa sa sacoche, enleva son pantalon, son pull-over, sa chemise, ses chaussures, ses chaussettes 
                  et quand il allait ôter son caleçon, il se regarda dans la glace et resta pétrifié.</p>
                <p>« Si j’enlève ce dernier vêtement je serai nu comme tous les autres » pensa-t-il. « Et si je me perds, comment 
                  pourrai-je me reconnaître ? Si je ne conserve pas cette particularité de la couleur marron qui jusqu’à présent 
                  m’a tant aidé ? »</p>
                <p>Pendant plus d’un quart d’heure il demeura dans les vestiaires, en sous-vêtement, doutant de lui et se demandant 
                  s’il ne ferait pas mieux de partir.</p>
                <p>Et alors, il s’aperçut que s’il ne pouvait pas rester habillé, il pouvait certainement utiliser un signe distinctif. 
                  Avec beaucoup de délicatesse, il enleva un fil de laine de son pull-over marron et il se l’attacha au gros orteil 
                  droit. « Je dois me souvenir de cela si je me perds : Celui qui porte le fil de laine au gros orteil droit, c’est 
                  moi. »</p>
                <p>Avec toute la sérénité du monde et fort de sa crédibilité, il put profiter des bains de vapeur, sans s’apercevoir 
                  qu’entre les différends bassins et plongeons, le fil de laine avait glissé de son doigt et flottait au beau milieu 
                  de l’eau.</p>
                <p>Un autre homme qui se trouvait là, en voyant le fil de laine qui flottait dit à son ami : « Quel drôle de hasard, 
                  c’est la couleur que je voulais décrire à ma femme pour qu’elle me tricote une écharpe. Je vais emporter le fil de 
                  laine pour qu’elle puisse trouver la même couleur. »</p>
                <p>En s’apercevant qu’il n’avait aucun endroit où garder le fil de laine, il se l’attacha au gros orteil droit.</p>
                <p>Pendant ce temps, le protagoniste de notre histoire avait profité de ses différends bains et se dirigeait vers 
                  les vestiaires. Il y entra d’un air décidé et satisfait.</p>
                <p>Il commença à se sécher. Quand il regarda dans la glace, il s’aperçut avec horreur que non seulement il était 
                  totalement nu mais qu’en plus il n’avait plus de fil de laine au gros orteil droit. « Je me suis perdu » pensa-t-il 
                  en tremblant et il sortit à la recherche du fil de laine marron qui l’identifiait.</p>
                <p>Quelques minutes après, en observant d’un regard avisé son entourage, il aperçut le pied de l’autre homme 
                  qui portait le fil de laine marron à son gros orteil droit. Timidement il s’approcha de lui et lui dit :</p>
                <p>« excusez-moi Monsieur, je sais qui vous êtes mais pourriez vous me dire qui je suis ? »</p>
              </em>
            </CardParagraph>
          </CardContent>
          <footer class="card-footer">
            <CardButton />
          </footer>
        </Card>
      </div>
      <div className="column">
        <Card>
          <CardContent>
            <CardTitle title="Se rendre compte" />
          </CardContent>
          <CardContent>
            <em className="content justified-content">
              <p>Je me lève le matin.</p>
              <p>Je sors de chez moi.</p>
              <p>Il y a un affaissement sur le trottoir.</p>
              <p>Je ne le vois pas</p>
              <p>et je tombe dedans.</p>
            </em>
            <CardParagraph>
              <em>
                <br />
                <p>Le lendemain<br />Je sors de chez moi,<br />j’oublie qu’il y a un affaissement sur le trottoir,
                  <br />et, je retombe dedans.</p>
                
                <p>Le troisième jour<br />je sors de chez moi en essayant de me souvenir<br />qu’il y a un affaissement sur le 
                trottoir.<br />Et pourtant,<br />j’oublie<br />et je tombe dedans.</p>
                
                <p>Le quatrième jour,<br />je sors de chez moi en essayant de me souvenir<br />de l’affaissement sur le trottoir.
                  <br />je m’en souviens et<br />malgré cela,<br />je ne le vois pas et je tombe dedans.</p>

                <p>Le cinquième jour,<br />je sors de chez moi.<br />Je sais que je dois me souvenir<br />de l’affaissement sur 
                  le trottoir<br />et je marche en regardant le sol.<br />Et je le vois et,<br />malgré cela,<br />je tombe dedans.</p>

                <p>Le sixième jour<br />je sors de chez moi.<br />Je me souviens de l’affaissement sur le trottoir.<br />Je le 
                  cherche des yeux.<br />Je le vois,<br />j’essaie de l’enjamber,<br /> je tombe dedans.</p>

                <p>Le septième jour<br />je sors de chez moi.<br />Je vois l’affaissement sur le trottoir.<br /> prends de 
                  l’élan,<br />je saute,<br />je frôle avec la pointe du pied le bord de l’affaissement,<br />et je tombe dedans.</p>

                <p>Le huitième jour,<br />je sors de chez moi,<br /> vois l’affaissement sur le trottoir,<br /> prends de l’élan,
                  <br />je saute,<br />j’arrive à le sauter !<br /> suis tellement fier d’y être arrivé<br /> je fais des bonds de 
                  joie…<br />Et en faisant cela,<br />je tombe à nouveau dedans.</p>

                <p>Le neuvième jour,<br />je sors de chez moi,<br />je vois l’affaissement sur le trottoir,<br />je prends de l’élan,
                  <br />je saute<br />et je poursuis mon chemin.</p>

                <p>Le dixième jour,<br />aujourd’hui,<br />je me rends compte<br />qu’il est plus simple<br />de marcher…<br />
                  Sur le trottoir d’en face</p>
              </em>
            </CardParagraph>
          </CardContent>
          <footer className="card-footer">
            <CardButton />
          </footer>
        </Card>
      </div>
    </div>
    <Box background="primary-light">
      <BoxTitle title="Conte africain - Auteur inconnu" />
    </Box>
    <div className="columns">
      <div className="column">
        <Card>
          <CardContent>
            <CardTitle title="Le secret du bonheur" />
          </CardContent>
          <CardContent>
            <em>
              <p>Un enfant demande à son père:</p>
              <p>- Dis papa, quel est le secret pour être heureux ? </p>
              <br/>
              <p>Alors le père demande à son fils de le suivre. Ils sortent de la maison, le père sur leur vieil âne et le fils 
                  suivant à pied. Et les gens du village de dire:<br />- Mais quel mauvais père qui oblige ainsi son fils d'aller 
                  à pied !<br />- Tu as entendu mon fils ? Rentrons à la maison, dit le père.</p>
            </em>
            <CardParagraph>
              <em>
                <br />
                

                <p>Le lendemain ils sortent de nouveau, le père ayant installé son fils sur l'âne et lui marchant à côté. Les gens 
                  du village dirent alors:<br />- Quel fils indigne, qui ne respecte pas son vieux père et le laisse aller à pied !
                  <br/>- Tu as entendu mon fils ? Rentrons à la maison.</p>

                <p>Le jour suivant, ils s'installent tous les deux sur l'âne avant de quitter la maison. Les villageois commentèrent 
                  en disant: <br />- Ils ne respectent pas leur bête à la surcharger ainsi!<br />- Tu as entendu mon fils ? Rentrons 
                  à la maison.</p>

                <p>Le jour suivant, ils partirent en portant eux-mêmes leurs affaires, l'âne trottinant derrière eux. Cette fois 
                  les gens du village y trouvèrent encore à redire:<br />- Voilà qu'ils portent eux-mêmes leurs bagages maintenant ! 
                  C'est le monde à l'envers !<br />- Tu as entendu mon fils ? Rentrons à la maison.</p>

                <p>Arrivés à la maison, le père dit à son fils:<br />- Tu me demandais l'autre jour le secret du bonheur.<br/>Peu 
                  importe ce que tu fais, il y aura toujours quelqu'un pour y trouver à redire.<br />Fais ce qui te plaît et tu 
                  seras heureux.</p>
              </em>
            </CardParagraph>
          </CardContent>
          <footer className="card-footer">
            <CardButton />
          </footer>
        </Card>
      </div>
      <div className="column">
        <Card>
          <CardImage src="contes2" alt="Contes" />
        </Card>
      </div>
    </div>
  </Layout>
)

export default Contes;